html{
  height: 100%;
}
body {
  margin: 0;
  font-family:'Courier New', Courier, monospace;
}
.clock{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 100px;
  height:100vh;
}
input{
  height: 30px;
  width: 70px;
  margin: 7px 0 0 5px;
  border: transparent;
}
